import * as React from "react"
import * as styles from "./index.module.css"
import Seo from "../components/seo"

const IndexPage = () => (
    <>
        <Seo title="Home"/>
        <h1 className={styles.hide}>Club Vineber</h1>
        <div className={styles.container}/>
    </>
)

export default IndexPage
